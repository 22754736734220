import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const LayoutFooter = () => {
  return (
    <div css={css`
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      padding: 30px 0;
    `}>
      <footer>
      © {new Date().getFullYear()} by NorthOmics
      </footer>
    </div>
  )
}

export default LayoutFooter