import React from "react"
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import img from '../images/northomics.png';

const LandingImage = () => {
  return (
    <div css={css`
      position: relative;
      text-align: center;
      color: #ddd;
    `}>
      <h1 css={css`
        font-size: 3.0rem;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}>NorthOmics Team Portal</h1>
      <img src={img} alt="" />
    </div>
  )
}
export default LandingImage
