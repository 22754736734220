import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "bulma/css/bulma.css"

// import components
import Header from "./layout.header"
import Footer from './layout.footer'
import SignInForm from './form.signin'
import FPForm from './form.forgotpassword'

import Firebase, {FirebaseContext} from './firebase.index';
import Image from "./image"
import Landing from "./landing";

class Layout extends React.Component {
  constructor(props){
    super(props);
    this.state= {
      authUser: null,
      showSignInForm: 'not-active',
      showFPForm: 'not-active'
    }
  }

  componentDidMount(){
    let firebase = new Firebase();
    this.listener = firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({authUser})
        : this.setState({authUser: null})
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  _onSignIn = () => {
    // console.log('attempt to sign in');
    this.setState({showSignInForm: 'is-active'})
  }

  _onSubmitForSignIn = (user) => {
    // console.log('attempt to submit sign in');
    // console.log(user);

    if(user){
      this.setState({ 
        showSignInForm: 'not-active',
        authUser: user
      })
    }
  }

  _onCancelSignIn = () => {
    this.setState({ showSignInForm: 'not-active'})
  }

  _onForgotPassword = () => {
    this.setState({ 
      showSignInForm: 'not-active',
      showFPForm: 'is-active'
    });
  }

  _closeFP = () => {
    this.setState({ showFPForm: 'not-active'});
  }

  render() {
    // console.log(this.props);
    // console.log(this.state);
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <FirebaseContext.Provider value={new Firebase()}>
            <Header 
              siteTitle = {data.site.siteMetadata.title} 
              signIn = {this._onSignIn} />

            <div
              style={{
                margin: `0 auto`,
                maxWidth: 1200,
                padding: `0px 1.0875rem 1.45rem`,
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100vh'
              }}>
              <main style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                {
                  this.state.authUser 
                  ? this.props.children
                  : <Landing/>
                }
              </main>
              <Footer />
            </div>

            <SignInForm 
              showSignInForm={this.state.showSignInForm}
              signInSubmit={this._onSubmitForSignIn}
              signInCancel={this._onCancelSignIn}
              onForgotPassword={this._onForgotPassword} />

            <FPForm 
              activeFP={this.state.showFPForm}
              closeFP={this._closeFP}
            />

          
          </FirebaseContext.Provider>
        )}
      />
    )
  }
}

const WelcomeImage = () => {
  return (
    <div style={{ width: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
