import React from 'react';

// the createContext() function creates two components
// FirebaseContext.Provider is used to provide a Firebase instance one time at the top level of react component tree
// FirebaseContext.Consumer is used to retrieve the Firebase instance when it is requried by other components

const FirebaseContext = React.createContext(null);

// a higher order component
export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
)

export default FirebaseContext;