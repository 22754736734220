import React from 'react';
import {Link} from 'gatsby';
import {PASSWORD_FORGET} from '../constants/routes';
/**
 * 
 * @param {object} props
 * @param {string} props.active: is-active vs not-active
 * @param {function} props.signInCancel: simply close modal
 * @param {function} props.signInSubmit: save changes
 */
const ModalForSignIn = (props) => {
  // console.log(props);
  return (
    <div className={`modal ${props.active}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Sign In</p>
          <button className="delete" 
            aria-label="close"
            onClick={props.signInCancel}></button>
        </header>
        <section className="modal-card-body">
          <SignInForm
            submit={props.signInSubmit}
            cancel={props.signInCancel} />
          <div><button className="button is-text"
            onClick={props.onForgotPassword}
          >Forgot your password?</button></div>
        </section>
      </div>
    </div>
  )
}

const FORMDATA = {
  email: "",
  password: ""
}

/**
 * the class component has its own internal state
 * it also converts array input into object
 * 
 * @param {object} state
 * @param {string} state.email
 * @param {string} state.password
 */
class SignInForm extends React.Component{
  constructor(props){
    super(props);
    this.state={...FORMDATA}
  }

  update = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]: value});
  }

  submit = () => {
    this.props.submit({...this.state});
    this.setState({...FORMDATA})
  }

  cancel = () => {
    this.props.cancel();
    this.setState({...FORMDATA})
  }

  render(){
    // console.log(this.state);
    return(
      <div>

        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input className="input" type="text" placeholder="johndow@uottawa.ca" 
              name="email"
              value={this.state.email}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Password</label>
          <div className="control">
            <input className="input" type="password" placeholder="random123*string"
              name="password"
              value={this.state.password}
              onChange={this.update} />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.submit}
            >Sign In</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalForSignIn