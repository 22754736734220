// Default data for MS Booking Table display
// the order of the data sets matter
export const INIT = [
  // table column one "Time"
  {type: "appliedTime", value: "", label: "Applied Time"},
  {type: "startTime", value: "pending...", label: "Start Time"},
  {type: "status", value: "pending...", label: "Status"},
  // table column two "Name"
  {type: "userName", value: "", label: "Name"},
  // table column three "Key Info"
  {type: "gradientOptions", value: "", label: "Gradient"},
  {type: "sampleCount", value: "", label: "# of Samples"},
  {type: "databaseTypeList", value: "", label: 'Species'},
  {type: "relatedProject", value: "", label: 'Project'},
  {type: "equipmentList", value: "", label: "Equipment"},
  // table column four "Supporting info"
  {type: "sampleReady", value: "", label: "Is the sample ready?"},
  {type: "urgencyLevels", value: "", label: "Is it urgent?"},
  {type: "digestionMethods", value: "", label: "Digestion method"},
  {type: "experimentTypes", value: "", label: "Experiment type"},
  {type: "additionalInfo", value: "", label: "Additonal info"},
  {type: "email", value: "", label: "Contact info"},
];


// *** element types with matching elements***
// type: 'list-simple' -> render: <ListOfSubmissionNotes>: '/elements/List.SubmissionNotes'
// type: 'list-messageboard' -> render: <ListOfMSRequestInstructions>: '/elements/List.MSRequestInstructions'
// type: 'input-radio' -> render: <InputElementAsRadio>: '/elements/Input.Radio'
// type: 'input-text' -> render: <InputElementAsText>: '/elements/Input.Text'
// type: 'select-simple' -> render: <SimpleSelectElement>: '/elements/Select.Simple'
// type: 'select-withinput' -> render: <SelectElementWithInput>: '/elements/Select.WithInput'
// type: 'input-area' -> render: <InputElementAsTextArea>: '/elements/Input.TextArea'

/**
 * important:
 * If you update the FormData and use 'Dangersouly update the form from file' function
 * make sure the newly added fields are updated in 'form.msrequest.js' too inside INIT
 * so that these information are visible in table
 */

// udpate version 1
export const updateRequestFormData = [
  {
    type: 'list-messageboard',
    title: "Please read the instructions below before filling out the booking form",
    nameAttr: "msRequestInstructions",
    instructions: 
    [
      "Reserve the time for your samples which are ready, not for all perspective samples, especially for large projects.",
      "When your samples are ready (digested), test them (pick some randomly if not all) on Orbitrap Elite before your real run. Do the test for each batch of your sample.",
      "If sample quality issue occurs during the sample run, the booking will be cancelled. New booking is needed when new preparations are ready.",
      "The priority in the queue is subjected to change according to project priority.",
      "During large project (perspective run takeing weeks or months), urgent small scale samples (e.g. samples less than 10) may squeeze in.",
      "There will be a 30min wash in between the sample analysis, count that time in when you estimate your total sample run.",
    ]
  },
  {
    type: 'input-radio',
    label: 'Are your samples ready?',
    help: '',
    optionA: 'Yes, they are ready',
    optionB: 'No, they will be ready soon',
    nameAttr: 'sampleReady'
  },
  {
    type: 'input-text',
    label: 'Name',
    help: '',
    placeholder: 'John Dow',
    nameAttr: 'userName'
  },
  {
    type: 'input-text',
    label: 'Which project is this analysis related To?',
    help: 'This information will help us to name the raw files so that they can be easily retrieved later.',
    placeholder: 'IBD Project',
    nameAttr: 'relatedProject'
  },
  {
    type: 'input-text',
    label: 'Enter the number of samples for this analysis',
    help: '',
    placeholder: '10',
    nameAttr: 'sampleCount'
  },
  {
    type: 'select-simple',
    label: "Select the urgence level for this analysis",
    value: "",
    nameAttr: 'urgencyLevels',
    help: "",
    options: [
      "Select an urgency level",
      "I am not in a hurry",
      "ASAP",
      "Very urgent, need to cut-in"
    ]
  },
  {
    type: 'select-simple',
    label: "Select the equipment",
    value: "",
    nameAttr: 'equipmentList',
    help: "",
    options: [
      "Select an equipment",
      "Orbitrap Elite",
      "Qtrap5500",
      "Q-Exactive-HFX",
      "Q-Exactive"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the digestion method you have used to process the sampels",
    nameAttr: "digestionMethods",
    value: "",
    help: "",
    options: [
      "Select a digestion method",
      "In-gel(comassie blue staining)",
      "In-gel(silver staining)",
      "In-solution (urea and desalted)",
      "FASP",
      "On beads from co-IP sample",
      "SCX/SAX reactor",
      "Lipid extraction",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the gradient length (min)",
    value: "",
    nameAttr: "gradientOptions",
    help: "Usually, select 60 for In-gel digestion; select 120 for profiling complex samples.",
    options: [
      "Selet a gradient",
      "60",
      "120",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the targeted species for your sample(s)",
    value: "",
    nameAttr: "databaseTypeList",
    help: "Will be used to determine which database will be used for searching",
    options: [
      "Select a database",
      "Human",
      "Mouse",
      "Rat",
      "Yeast",
      "CHO",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the experiment/analysis type",
    value: "",
    nameAttr: "experimentTypes",
    help: "",
    options: [
      "Select an experiment type",
      "Protein identification",
      "Protein identification and quantification",
      "MRM analysis",
      "Lipid Analysis",
      "Others"
    ]
  },
  {
    type: 'input-area',
    label: 'Message',
    help: 'You are also welcome to provide additional information that you think will help us to assist you better.',
    placeholder: 'Enter your addtional requests here...',
    nameAttr: 'additionalInfo'
  },
  {
    type: 'list-simple',
    nameAttr: "submissionNotes",
    list:[
      "All available equipments are currently on a \"First-Come, First-Served\" basis for all requests.",
      "You will also be noticed ahead of time when the requested equipment is becoming ready."
    ]
  }
];

// Array format of the request form data
export const defaultRequestFormData = [
  {
    type: 'list-messageboard',
    title: "Please read the instructions below before filling out the booking form",
    nameAttr: "msRequestInstructions",
    instructions: 
    [
      "Reserve the time for your samples which are ready, not for all perspective samples, especially for large projects.",
      "When your samples are ready (digested), test them (pick some randomly if not all) on Orbitrap Elite before your real run. Do the test for each batch of your sample.",
      "If sample quality issue occurs during the sample run, the booking will be cancelled. New booking is needed when new preparations are ready.",
      "The priority in the queue is subjected to change according to project priority.",
      "During large project (perspective run takeing weeks or months), urgent small scale samples (e.g. samples less than 10) may squeeze in.",
      "There will be a 30min wash in between the sample analysis, count that time in when you estimate your total sample run.",
    ]
  },
  {
    type: 'input-radio',
    label: 'Are your samples ready?',
    help: '',
    optionA: 'Yes, they are ready',
    optionB: 'No, they will be ready soon',
    nameAttr: 'sampleReady'
  },
  {
    type: 'input-text',
    label: 'Which project is this analysis related To?',
    help: 'This information will help us to name the raw files so that they can be easily retrieved later.',
    placeholder: 'IBD Project',
    nameAttr: 'relatedProject'
  },
  {
    type: 'input-text',
    label: 'Enter the number of samples for this analysis',
    help: '',
    placeholder: '10',
    nameAttr: 'sampleCount'
  },
  {
    type: 'select-simple',
    label: "Select the urgence level for this analysis",
    value: "",
    nameAttr: 'urgencyLevels',
    help: "",
    options: [
      "Select an urgency level",
      "I am not in a hurry",
      "ASAP",
      "Very urgent, need to cut-in"
    ]
  },
  {
    type: 'select-simple',
    label: "Select the equipment",
    value: "",
    nameAttr: 'equipmentList',
    help: "",
    options: [
      "Select an equipment",
      "Orbitrap Elite",
      "Qtrap5500",
      "Q-Exactive-HFX",
      "Q-Exactive"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the digestion method you have used to process the sampels",
    nameAttr: "digestionMethods",
    value: "",
    help: "",
    options: [
      "Select a digestion method",
      "In-gel(comassie blue staining)",
      "In-gel(silver staining)",
      "In-solution (urea and desalted)",
      "FASP",
      "On beads from co-IP sample",
      "SCX/SAX reactor",
      "Lipid extraction",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the gradient length (min)",
    value: "",
    nameAttr: "gradientOptions",
    help: "Usually, select 60 for In-gel digestion; select 120 for profiling complex samples.",
    options: [
      "Selet a gradient",
      "60",
      "120",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the targeted species for your sample(s)",
    value: "",
    nameAttr: "databaseTypeList",
    help: "Will be used to determine which database will be used for searching",
    options: [
      "Select a database",
      "Human",
      "Mouse",
      "Rat",
      "Yeast",
      "CHO",
      "Others"
    ]
  },
  {
    type: 'select-withinput',
    label: "Select the experiment/analysis type",
    value: "",
    nameAttr: "experimentTypes",
    help: "",
    options: [
      "Select an experiment type",
      "Protein identification",
      "Protein identification and quantification",
      "MRM analysis",
      "Lipid Analysis",
      "Others"
    ]
  },
  {
    type: 'input-area',
    label: 'Message',
    help: 'You are also welcome to provide additional information that you think will help us to assist you better.',
    placeholder: 'Enter your addtional requests here...',
    nameAttr: 'additionalInfo'
  },
  {
    type: 'list-simple',
    nameAttr: "submissionNotes",
    list:[
      "All available equipments are currently on a \"First-Come, First-Served\" basis for all requests.",
      "You will also be noticed ahead of time when the requested equipment is becoming ready."
    ]
  }
]