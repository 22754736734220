import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.nameAttr
 * @param {array} props.options
 * @param {string} props.value (not used for admin)
 * @param {function} props.update
 * 
 * @param {object} state
 * @param {boolean} state.selection
 * @param {boolean} state.showInput
 * @param {string} state.input
 */
class SelectElementWithInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selection: '',
      showInput: false,
      input: ""
    }
  }

  // update through input
  handleInput = (e) => {
    let input = e.target.value;
    this.setState({input});

    let obj = {};
    obj.type = this.props.nameAttr;
    obj.value = input;
    this.props.update(obj);
  }

  // update through select
  update = (e) => {
    let selection = e.target.value;

    if(selection !== "Others"){
      if(selection !== this.props.options[0]){
        this.setState({
          selection: selection,
          showInput: false,
          input: ''
        });

        let obj = {};
        obj.type = this.props.nameAttr;
        obj.value = selection;
        this.props.update(obj);
      }else{
        this.setState({
          selection: '',
          showInput: false,
          input: ''
        });
      }
    }else{
      this.setState({
        selection: selection,
        showInput: true,
        input: ''
      });
    }
  }

  render(){
    // console.log(this.state);
    // console.log(this.props);
    return (
      <div css={css`margin: 15px 0;`}>
        <div className="field">
          <label className="label">{this.props.label}</label>
          <p css={css`
          font-size: 0.9rem;
          color: #283593;
          padding: 0px 0px 10px 0px;
        `}>{this.props.help}</p>
          <div className="control">
            <span className="select">
              <select
                name={this.props.nameAttr} 
                value={this.state.selection}
                onChange={this.update}>
                {
                  this.props.options.map((option, index)=>{
                    return (
                      <option key={index}>{option}</option>
                    )
                  })
                }
              </select>
            </span>
          </div>
        </div>
        
        {
          this.state.showInput
          &&
          (
            <div className="field">
              <label className="label">{`Please specify:`}</label>
              <div className="control">
                <input className="input" 
                  type="text" 
                  value={this.state.input}
                  onChange={this.handleInput} 
                />
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default SelectElementWithInput;