import React from "react"
import { Link } from "gatsby"
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from '../components/firebase.context';
// import components
import Layout from "../components/layout"

const ForgotPasswordModal = (props) => {
  return (
    <div className={`modal ${props.activeFP}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Reset password</p>
          <button className="delete" 
            aria-label="close"
            onClick={props.closeFP}></button>
        </header>
        <section className="modal-card-body">
          <ForgotPasswordForm 
            firebase = {props.firebase}
          />
        </section>
      </div>
    </div>
  )
}

class ForgotPasswordForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      email: '',
      passwordReset: '',
    }
  }

  _onChange = (e) => {
    this.setState({email: e.target.value});
  }

  _resetPassword = () => {
    this.props.firebase.doPasswordReset(this.state.email)
    .then(()=>{
      this.setState({passwordReset: 'success'});
    })
    .catch((err)=>{
      console.log(err);
      this.setState({passwordReset: 'failed'});
    });
  }

  render(){
    return(
      <div>
        <h1>Reset your password</h1>
        <div className="field">
          <label className="label">Please enter your email address</label>
          <div className="control">
            <input className="input" 
              type="text" 
              placeholder="johndow@uottawa.ca"
              name='email'
              value={this.state.email}
              onChange={this._onChange} 
            />
          </div>
        </div>

        <button className="button is-info"
          onClick={this._resetPassword}
        >Request to reset your password</button>

        {
          this.state.passwordReset === 'success'
          &&
          <div css={css`
            margin: 20px 0;
          `}>
            <div className="notification is-success">
              A secured link will be sent to your email to allow you to reset your password.
            </div>
          </div>
        }
        {
          this.state.passwordReset === 'failed'
          &&
          <div css={css`
            margin: 20px 0;
          `}>
            <div className="notification">
              Password reset failed.  Please check your internet connection or contact <code>techteam.metalab@gamil.com</code> for support. 
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withFirebase(ForgotPasswordModal)
