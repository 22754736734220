import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.help: optional
 * @param {string} props.nameAttr
 * @param {string} props.errMsg - show error || null
 * @param {array} props.options - all options
 * @param {string} props.defaultItem: optional: the default item
 * 
 * @param {array} props.selectedOption: conditional, NOT always needed, currently selected option, used to reset state, 
 * 
 * @param {string} props.value NOT needed for admin
 * @param {function} props.update
 */

class SimpleSelectElement extends React.Component {
  constructor(props){
    super(props);
    this.state={
      selectedItem: ''
    }
  }

  componentDidMount(){
    this.setState({
      selectedItem: this.props.defaultItem
    })
  }

  componentDidUpdate(){

    // reset state
    if(this.props.selectedOption){
      if(this.props.selectedOption !== this.state.selectedItem){
        this.setState({selectedItem: this.props.selectedOption})
      }
    }
    
  }

  update = (e) => {
    // console.log(e.target.value);
    let selectedItem = e.target.value;
    if(selectedItem !== this.props.options[0]){
      this.setState({selectedItem});

      let obj = {};
      obj.type = this.props.nameAttr;
      obj.value = selectedItem;
      this.props.update(obj);
    }
  }

  render(){
    // console.log(this.state);
    // console.log(this.props);
    return (
      <div className="field">
        <label className="label">{this.props.label}</label>
        <p css={css`
          font-size: 0.9rem;
          color: #283593;
          padding: 0px 0px 10px 0px;
        `}>{this.props.help}</p>
        <div className="control">
          <span className={this.props.errMsg ? "select is-danger": "select"}>
            <select
              name={this.props.nameAttr} 
              value={this.state.selectedItem}
              onChange={this.update}>
              {
                this.props.options.map((option, index)=>{
                  return (
                    <option key={index}>{option}</option>
                  )
                })
              }
            </select>
          </span>
          <p className="help is-danger">{this.props.errMsg}</p>
        </div>
      </div>
    )
  }
}

export default SimpleSelectElement;

SimpleSelectElement.defaultProps = {
  label: '',
  help: '',
  nameAttr: '',
  errMsg: null,
  options: [],
  selectedOption: '',
  update: ()=> {}
}