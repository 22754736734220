import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {Link} from 'gatsby';
import { IoMdHome, IoIosListBox } from 'react-icons/io';

/**
 * 
 * @param {object} props
 * @param {string} props.link: link to route one level above
 * @param {string} props.name: name for current route level 
 */
const GeneralBreadcrumb = (props) => {
  return (
    <div css={css`
        margin: 0;
        align-self: flex-start;
      `}>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to={props.link}><IoMdHome />Home</Link>
          </li>
          <li className="is-active"><a href="#" aria-current="page"><IoIosListBox />{props.name}</a></li>
        </ul>
      </nav>
    </div>
  )
}

export default GeneralBreadcrumb;