import React from 'react';
// import firebase
import {withFirebase} from './firebase.context';
// import elements
import SignInModal from '../elements/Modal.SignIn';

class SignInFormBase extends React.Component {
  
  _onSubmitForSignIn = (data) => {
    // console.log('attempt to submit sign in');
    // console.log(data);
    let email = data.email;
    let password = data.password;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((res)=>{
        // console.log(res);
        // console.log(res.user);
        // console.log(res.user.email);
        // console.log(res.user.uid);
        this.props.signInSubmit(res.user.email);
      })
      .catch(error => {
        // console.log(error);
        let errorMsg = error.message;
        // console.log(errorMsg);
      })
  }

  render(){
    // console.log(this.props);
    return (
      <div>
        <SignInModal 
          active={this.props.showSignInForm}
          signInCancel={this.props.signInCancel}
          signInSubmit={this._onSubmitForSignIn}
          onForgotPassword={this.props.onForgotPassword} />
      </div>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase);

export default SignInForm