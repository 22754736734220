import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {Link} from 'gatsby';
// import firebase
import {withFirebase} from './firebase.context';
// import components
import ListOfMSRequestInstructions from '../elements/List.MSRequestInstructions';
import InputElementAsRadio from '../elements/Input.Radio';
import InputElementAsText from '../elements/Input.Text';
import InputElementAsTextArea from '../elements/Input.TextArea';
import ListOfSubmissionNotes from '../elements/List.SubmissionNotes';
import SimpleSelectElement from '../elements/Select.Simple';
import SelectElementWithInput from '../elements/Select.WithInput';
// import data
import {INIT} from '../constants/msRequestFormData';

const MSRequestComponent = (props) => {
  return (
    <div css={css`
      width: 100%;
      padding: 20px 0;
      h2 {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 20px 0;
      }
    `}>
      <h2>MS Request Form</h2>
      <MSRequestForm firebase={props.firebase}/>
    </div>
  )
}

const getSubmissionTime = () => {
  let dateTime = "";
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  dateTime = date+' '+time;
  return dateTime;
}

class MSRequestForm extends React.Component{
  constructor(props){
    super(props);
    this.state={
      msRequestFormData: [], //array
      formInputData: [...INIT], //array
      checkSubmission: false
    }
  }

  componentDidMount(){
    // console.log("ms request admin form did mount");
    this.props.firebase.msrequestform().get()
    .then((querySnapshot)=>{
      querySnapshot.forEach((doc)=>{
        let _data = JSON.parse(JSON.stringify(doc.data().data));
        this.setState({msRequestFormData: _data});
      })
    })
    .catch((error)=>{
      console.log(error);
    });
  }

  update = (value) => {
    // console.log('update values');
    // console.log(value);

    let _value = {...value};
    let _inputDataArray = JSON.parse(JSON.stringify(this.state.formInputData));

    _inputDataArray.forEach((input, index)=>{
      if (value.type === input.type){
        _inputDataArray[index]['value'] = _value.value;
        this.setState({formInputData: _inputDataArray});
      }
    })
  }

  // save changes made on elements
  submit = () => {
    // make a deep clone
    let _array = JSON.parse(JSON.stringify(this.state.formInputData)); //array
    
    // update applied time value
    let appliedTime = getSubmissionTime();

    // get user email
    let _email = this.props.firebase.auth.currentUser.email;
    // console.log(_email);

    // timestamp for data entry
    let _timestamp = this.props.firebase.timestamp;

    _array.forEach((input, index)=>{
      if(input.type === 'appliedTime'){
        // console.log('circling through array');
        _array[index]['value'] = appliedTime;
      }else if(input.type === 'email'){
        _array[index]['value'] = _email;
      }
    });
    
    // update data in database {{data: [{},{}]}, created: {}}
    this.props.firebase.msrequestdata().add({data: _array, created: _timestamp})
    .then(()=>{
      // console.log('update to database successful');
      this.setState({
        formInputData: [...INIT],
        checkSubmission: true
      });
    })
    .catch(err=>{
      console.log(err);
      this.setState({formInputData: [...INIT]});
    });
  }

  render(){
    // *** element types with matching elements***
    // type: 'list-simple' -> render: <ListOfSubmissionNotes>
    // type: 'list-messageboard' -> render: <ListOfMSRequestInstructions>
    // type: 'input-radio' -> render: <InputElementAsRadio>
    // type: 'input-text' -> render: <InputElementAsText>
    // type: 'select-simple' -> render: <SimpleSelectElement>
    // type: 'select-withinput' -> render: <SelectElementWithInput>
    // type: 'input-area' -> render: <InputElementAsTextArea>

    // deep clone of msRequestFormData
    let data = JSON.parse(JSON.stringify(this.state.msRequestFormData));
    // console.log(data);
    // console.log(this.state);
    return(
      <div>

        {
          data.map((_elementData, index)=>{

            return(
              <div key={index} css={css`margin: 20px 0;`}>
              {
                _elementData.type === 'list-simple'
                &&
                <ListOfSubmissionNotes 
                  list={_elementData.list}
                  nameAttr={_elementData.nameAttr}
                />
              }
              {
                _elementData.type === 'list-messageboard'
                &&
                <ListOfMSRequestInstructions 
                  title={_elementData.title}
                  instructions={_elementData.instructions}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'input-radio'
                &&
                <InputElementAsRadio 
                  label={_elementData.label}
                  help={_elementData.help}
                  optionA={_elementData.optionA}
                  optionB={_elementData.optionB}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'input-text'
                &&
                <InputElementAsText 
                  label={_elementData.label}
                  help={_elementData.help}
                  placeholder={_elementData.placeholder}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'input-area'
                &&
                <InputElementAsTextArea 
                  label={_elementData.label}
                  help={_elementData.help}
                  placeholder={_elementData.placeholder}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'select-simple'
                &&
                <SimpleSelectElement 
                  label={_elementData.label}
                  value={_elementData.value}
                  help={_elementData.help}
                  options={_elementData.options}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'select-withinput'
                &&
                <SelectElementWithInput 
                  label={_elementData.label}
                  value={_elementData.value}
                  help={_elementData.help}
                  options={_elementData.options}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              </div>
            )
          })
        }

        <div className="field is-grouped">
          {
            !this.state.checkSubmission
            &&
            <div className="control">
              <button className="button is-link"
                onClick={this.submit}>Submit</button>
            </div>
          }
          {
            this.state.checkSubmission
            &&
            <div className="control">
              <Link className="button is-success"
                to="/"
              >Submission successful: Check your submission here</Link>
            </div>
          }
          
        </div>
        
      </div>
    )
  }
}

export default withFirebase(MSRequestComponent);
