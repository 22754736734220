import React from 'react';
import { Link } from "gatsby"
// import components
import Layout from "../components/layout"
import MSRequestForm from "../components/form.msrequest";
import BreadcrumbComponent from '../elements/Breadcrumb.general';

const MSRequest = () => {
  return (
    <Layout>
      <BreadcrumbComponent 
        link="/"
        name="MS Reqeust"
      />
      <MSRequestForm />
    </Layout>
  )
}

export default MSRequest