import React from 'react';

/**
 * 
 * @param {object} props
 * @param {string} props.title
 * @param {array} props.instructions 
 */
const ListOfMSRequestInstructions = (props) => {
  return (
    <article className="message is-info">
      <div className="message-header">
        <p>{props.title}</p>
        <button className="delete" aria-label="delete"></button>
      </div>
      <div className="message-body">
        <ul>
          {
            props.instructions.map((item, index)=>{
              return (
                <li key={index}>{`${index + 1}. ${item}`}</li>
              )
            })
          }
        </ul>
      </div>
    </article>
  )
}

export default ListOfMSRequestInstructions;