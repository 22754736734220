import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// import routes
import * as ROUTES from '../constants/routes';
// import firebase
import {withFirebase} from './firebase.context';
// import components
import SignOutButton from './button.signout';
import SignInButton from './button.signin';
// import data
import {adminUserList} from '../constants/adminUsers';

// check whether use has admin access based on admin user list
const checkUserStatus = (user) => {
  let authorized = false;
  adminUserList.forEach((admin)=>{
    if(user === admin){
      authorized = true;
    }
  })
  return authorized;
}

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state={
      email: null
    }
  }

  componentDidMount(){
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({email: authUser.email})
        : this.setState({email: null})
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  render(){
    let isAdmin = checkUserStatus(this.state.email);

    return (
      <header
        style={{
          background: `rebeccapurple`,
          marginBottom: `1.45rem`,
        }}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.2rem 0.8rem`,
          }}>
          <div style={{
            display: `flex`,
            flexDirection: `row`,
            flexWrap: `nowrap`,
          }}>

            <div style={{ 
              flexGrow: 1,
              fontSize: `2.2em`,
              margin: 0,
              padding: 0,
              fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
          Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
              fontWeight: `bold`,
              lineHeight: 1.1
            }}>
              <Link
                to={ROUTES.HOME}
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                {this.props.siteTitle}
              </Link>
            </div>
            {
              isAdmin
              &&
              <div css={css`
                padding: 0.5rem;
                line-height: 1.8;
                font-weight: bold;
                border: 1px solid transparent;
                &:hover {
                  border: 1px solid #eee;
                  transition: 2s ease;
                  border-radius: 10px;
                  cursor: pointer;
                }
              `}
              >
                <Link
                  to={ROUTES.ADMIN}
                  style={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                >
                  Admin
                </Link>
              </div>
            }
            
            <div css={css`
              align-self: flex-end;
              padding: 0.5rem;
              color: white;
              text-decoration: none;
              line-height: 1.8;
              font-weight: bold;
              border: 1px solid transparent;
              &:hover {
                border: 1px solid #eee;
                transition: 2s ease;
                border-radius: 10px;
                cursor: pointer;
              }
            `}
            >
            {
              this.state.email
              &&
              <SignOutButton />
            }
            {
              !this.state.email
              &&
              <SignInButton onClick={this.props.signIn}/>
            }
            
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withFirebase(Header)
