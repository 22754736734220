import React from 'react';

/**
 * 
 * @param {object} props 
 * @param {string} props.label
 * @param {string} props.help
 * @param {string} props.placeholder
 * @param {string} props.nameAttr
 * @param {function} props.update
 */
class InputElementAsText extends React.Component {
  constructor(props){
    super(props);
    this.state={
      input: ""
    }
  }

  _onChange = (e) => {
    let input = e.target.value;
    this.setState({input});

    let obj = {};
    obj.type = this.props.nameAttr;
    obj.value = input;
    this.props.update(obj);
  }

  render(){
    return (
      <div className="field">
        <label className="label">{this.props.label}</label>
        <p>{this.props.help}</p>
        <div className="control">
          <input className="input" 
            type="text" 
            placeholder={this.props.placeholder} 
            name={this.props.nameAttr}
            value={this.state.input}
            onChange={this._onChange} 
          />
        </div>
      </div>
    )
  }
}

export default InputElementAsText;