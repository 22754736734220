import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { IconContext } from "react-icons";
import { IoMdInformationCircle } from 'react-icons/io';
/**
 * 
 * @param {object} props
 * @param {array} props.list
 */
const ListOfSubmissionNotes = (props) => {
  // console.log(props);
  return (
    <div className="field">
      <div className="control">
        {
          props.list.map((note, index)=>{
            return(
            <div key={index}>
              <IconContext.Provider value={{color:'orange', size: 22}}>
                <span css={css`vertical-align: sub;`}>
                  <IoMdInformationCircle/>
                </span>
              </IconContext.Provider>
              <span>{note}</span>
            </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default ListOfSubmissionNotes;