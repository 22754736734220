import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyAKU0z3Pksu6UzmRn2gKPCxyYgnKz4Cknw",
  authDomain: "northomics-intraweb.firebaseapp.com",
  databaseURL: "https://northomics-intraweb.firebaseio.com",
  projectId: "northomics-intraweb",
  storageBucket: "northomics-intraweb.appspot.com",
  messagingSenderId: "738423295340",
  appId: "1:738423295340:web:940ba724ee2192c8"
};

class Firebase {
  constructor() {
    if(typeof window !== "undefined") {
      
      if (!app.apps.length) {
          app.initializeApp(config);
      }

      // *** Firebase APIs ***
    
      this.auth = app.auth(); //authetication
      this.db = app.firestore(); //firestore database
      this.storage = app.storage(); //cloud storage ref
      this.timestamp = app.firestore.Timestamp.now();
    }
  }

  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  onAuthStateChange = () => this.auth.onAuthStateChanged();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  getCurrentuserEmail = () => this.auth.currentUser.email;

  // *** Firebase cloud storage API ***

  // storageRef = this.storageRef;

  // fileRef = app.storage().ref().child('/test/file.md');

  // *** Firestore API ***

  // create a collection for lab meeting schedule
  // collection end point: /database/users
  users = () => this.db.collection('users');

  // create a document for a single user in firebase database based on the user uid
  // not implemented yet...
  user = uid => this.db.doc(`users/${uid}`);
  
  // create a collection for ms status data
  // collection end point: /database/mstatus <- pay attention to the spelling :)
  msstatus = () => this.db.collection(`mstatus`);
  
  // create a collection for ms request form, which holds the data for the ms request form
  // collection end point: /database/msrequestform
  msrequestform = () => this.db.collection(`msrequestform`);
 
  // create a collection for ms request data, which is data a user submit
  // collection end point: /database/msrequestdata
  msrequestdata = () => this.db.collection(`msrequestdata`);

  // file categories
  // collection end point: /database/filecategories
  filecategories = () => this.db.collection(`filecategories`);

  // file tag list
  // collection end point: /database/filetaglist
  filetaglist = () => this.db.collection(`filetaglist`);

  // *** custom APIs

  // timestamp
  // absolute time point in firestore database
  timestamp = this.timestamp;
}

export default Firebase;
 