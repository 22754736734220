import React from 'react';

/**
 * 
 * @param {object} props 
 * @param {string} props.label
 * @param {string} props.help
 * @param {string} props.optionA
 * @param {string} props.optionB
 * @param {string} props.nameAttr
 * @param {function} props.update
 */
const InputElementAsRadio = (props) => {
  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <p>{props.help}</p>
      <div className="control">
        <label className="radio">
          <input type="radio" 
            name={props.nameAttr}
            value={props.optionA}
            onChange={(e)=>props.update({type: props.nameAttr, value: e.target.value})} 
          />
          {props.optionA}
        </label>
        <label className="radio">
          <input type="radio" 
            name={props.nameAttr} 
            value={props.optionB}
            onChange={(e)=>props.update({type: props.nameAttr, value: e.target.value})}
          />
          {props.optionB}
        </label>
      </div>
    </div>
  )
}

export default InputElementAsRadio;